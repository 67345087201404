import { makePersisted } from "@solid-primitives/storage";
import { createStore } from "solid-js/store";
import type { FormSystemName } from "~/types/form_system_name";

type FormAlreadySubmittedLocalStorage = Record<string, string>;

export function localStorageKnowsIfAlreadySubmittedForNid(
  form_id: FormSystemName,
  nid: number,
): boolean {
  const [store] = makePersisted(
    createStore<FormAlreadySubmittedLocalStorage>({}),
    { name: "form_already_submitted" },
  );

  if (!store[`used_download_${form_id}_form_nids`]) {
    return false;
  }

  const nids: number[] =
    store[`used_download_${form_id}_form_nids`]
      .split(",")
      .map((el) => parseInt(el)) || ([] as number[]);

  return nids.includes(nid);
}

export function setLocalStorageAlreadySubmittedFormForNid(
  form_id: FormSystemName,
  nid: number,
): void {
  const [store, setStore] = makePersisted(
    createStore<FormAlreadySubmittedLocalStorage>({}),
    {
      name: "form_already_submitted",
    },
  );

  let nids: number[];
  if (!store[`used_download_${form_id}_form_nids`]) {
    nids = [];
  } else {
    nids =
      store[`used_download_${form_id}_form_nids`]
        .split(",")
        .map((el) => parseInt(el)) || ([] as number[]);
  }

  // Push the new value
  nids = [...nids, nid];
  // Make it unique only
  const unique = [...new Set(nids)];

  setStore(`used_download_${form_id}_form_nids`, unique.join(","));
}
